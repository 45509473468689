<template>
  <center-content>
    <base-card footer="bg-e0 py-0">
      <template #header>تأكيد رقم الهاتف</template>
      <template #body>
        <div v-if="hasMobile">
          <div v-if="isFirstTime">
            <p class="text-left font-weight-bold">
              <label class="my-0"
                >لإستخدام موقع الاردنية الاولى للمزادات يلزم تأكيد رقم الهاتف
                الخاص بك</label
              >
            </p>
            <button class="mt-3 btn rounded-0 btn-dark" @click="setFirstTime">
              تأكيد
            </button>
          </div>
          <form v-else @submit.prevent="confirm">
            <p class="link danger text-left">
              <span>تم إرسال رمز التأكيد الى رقم الهاتف الخاص بك</span>
            </p>
            <p class="pm-0 text-left">رمز التأكيد</p>
            <form-input
              id="code"
              type="number"
              placeholder="رمز التأكيد"
              autofocus
            ></form-input>
            <p class="pm-0 text-left">
              <label>لم تستلم الرمز ؟</label>
              <button
                type="button"
                class="link ml-2"
                v-if="canResend"
                @click="setCode"
              >
                إعادة إرسال
              </button>
              <label class="text-danger" v-else>&nbsp;{{ interval }}</label>
            </p>
            <button class="btn nr-shadow btn-dark m-0 mt-4">تأكيد</button>
          </form>
        </div>
        <form v-else @submit.prevent="store">
          <p class="text-left link danger font-weight-bolder">
            لإستخدام موقع الاردنية الاولى للمزادات يلزم إضافة رقم هاتف فعال
          </p>
          <div class="text-left">
            <label>رقم الهاتف</label>
            <form-input
              id="mobile"
              type="number"
              placeholder="رقم الهاتف"
              autofocus
            ></form-input>
            <div class="w-100 d-flex justify-content-center">
              <button class="btn nr-shadow btn-dark m-0 mt-4">إضافة</button>
            </div>
          </div>
        </form>
      </template>
      <!--      <template #footer v-if="hasMobile">-->
      <!--        <p class="text-left font-weight-bold">إذا كان لديك الوصول لرقم الهاتف الموجود بالأعلى يرجى الضغط على زر تأكيد للمتابعة</p>-->
      <!--      </template>-->
    </base-card>
  </center-content>
</template>

<script>
import FormInput from "@/components/Forms/FormInput";
import CenterContent from "@/components/UI/CenterContent";
import BaseCard from "@/components/UI/BaseCard";
import Storage from "@/common/Storage";
import crypt from "@/common/crypter";

export default {
  components: { BaseCard, CenterContent, FormInput },
  data() {
    return {
      sendTimes: 0,
      interval: "",
      canResend: true,
      isFirstTime: true,
    };
  },
  computed: {
    authorized() {
      return this.$store.getters.authorized;
    },
    hasMobile() {
      return !!this.authorized && !!this.authorized.mobile;
    },
    storedInterval() {
      let storedInterval = Storage.get("interval");
      if (
        !storedInterval ||
        !storedInterval.data ||
        parseInt(storedInterval.data) === 0
      )
        return "";
      return parseInt(storedInterval.data);
    },
  },
  methods: {
    async setCode() {
      await this.checkSendTimes();
      if (this.sendTimes >= 3) {
        http.popupMessage(
          "error",
          "تم إرسال الرمز عدة مرات يرجى التأكد من رقم الهاتف"
        );
        return false;
      }
      const response = await http.send("set-code", {
        user: this.authorized.id,
        mobile: this.authorized.mobile,
      });
      if (http.responseAccepted(response)) {
        this.canResend = false;
        this.setTimer();
        http.popupMessage("success", "تم الإرسال بنجاح");
        Storage.set("sendTimes", crypt.encrypt(++this.sendTimes)); // commented
        return true;
      }
      return false;
    },
    async confirm(e) {
      const formData = new FormData(e.target);
      formData.set("user", this.authorized.id);
      const response = await http.send("verify-code", formData);
      if (http.responseAccepted(response)) {
        if (response.data.verified) {
          const authorized = this.authorized;
          authorized["status"] = response.data.status;
          await this.$store.dispatch("setAuthorized", authorized);
          http.popupMessage("success", "تم التأكيد بنجاح");
          this.resetDefault();
        } else {
          http.popupMessage("error", "رمز التأكيد غير صالح");
        }
      }
      await this.$router.replace("/");
    },
    async store(e) {
      const formData = new FormData(e.target);
      formData.set("user", this.authorized.id);
      const response = await http.send("mobiles", formData);
      if (http.responseAccepted(response, "mobile")) {
        const authorized = this.authorized;
        authorized["mobile"] = response.data.mobile.mobile;
        await this.$store.dispatch("setAuthorized", authorized);
        this.resetDefault();
      }
    },
    setTimer(timeout = 60) {
      this.interval = timeout;
      const interval = setInterval(() => {
        this.canResend = false;
        Storage.set("interval", --this.interval); //done
        if (this.interval <= 0) {
          Storage.remove("interval");
          clearInterval(interval);
          this.canResend = true;
        }
      }, 1000);
    },
    async setFirstTime() {
      const sent = await this.setCode();
      if (sent) {
        this.clearStorage();
        this.isFirstTime = false;
        Storage.set("isFirstTime", this.isFirstTime); // commented
      }
    },
    checkInitialized() {
      const first = Storage.get("isFirstTime");
      if (!!first && first.data === false) this.isFirstTime = false;
      if (!!this.storedInterval) {
        this.canResend = false;
        this.setTimer(this.storedInterval);
      }
    },
    checkSendTimes() {
      let storedSendTimes = Storage.get("sendTimes");
      storedSendTimes = !!storedSendTimes ? storedSendTimes.data : "";
      const sendTimes = !!this.sendTimes
        ? this.sendTimes
        : crypt.decrypt(storedSendTimes);
      if (!!sendTimes && parseInt(sendTimes) > 0)
        this.sendTimes = parseInt(sendTimes);
    },
    clearStorage() {
      Storage.remove("sendTimes");
      Storage.remove("interval");
      Storage.remove("isFirstTime");
    },
    resetDefault() {
      this.clearStorage();
      this.interval = "";
      this.sendTimes = 0;
      this.isFirstTime = true;
      this.canResend = true;
    },
  },
  created() {
    this.checkInitialized();
  },
  async mounted() {
    if (!this.authorized) await this.$store.dispatch("logout");
  },
};
</script>

<style scoped>
label {
  margin: 0.25rem;
}

.nr-shadow {
  margin-top: 1rem;
}
</style>
